html {
  height: 100%; }

body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: #575757;
  overflow-y: scroll; }
  body:before {
    content: '';
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(src/images/shattered.png) left top repeat; }
  body:after {
    content: '';
    position: fixed;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #283048;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #859398, #283048);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #859398, #283048);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

#header {
  height: 200px;
  width: 100%;
  position: fixed;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 999;
  background: #283048;
  background: -webkit-linear-gradient(to bottom, rgba(40, 48, 72, 0.5), rgba(40, 48, 72, 0));
  background: linear-gradient(to bottom, rgba(40, 48, 72, 0.5), rgba(40, 48, 72, 0)); }
  #header div {
    width: 33.33%; }
    #header div:nth-child(1) {
      padding-left: 2rem; }
    #header div:nth-child(2) {
      text-align: center; }
      #header div:nth-child(2) img {
        max-width: 300px; }
    #header div:nth-child(3) {
      text-align: right;
      padding-right: 2rem; }

#main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  #main #content {
    max-width: 980px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 5rem 0; }
    #main #content .quote {
      position: relative;
      margin: 1.5rem 0 3rem; }
      #main #content .quote .the-quote {
        margin: 2rem;
        padding: 6rem 3rem 2rem;
        background-color: #f8f8ff;
        box-shadow: 0 19px 38px -20px rgba(0, 0, 0, 0.2), 0 15px 12px rgba(0, 0, 0, 0.12);
        border-radius: 0 0 1rem 1rem;
        border-top: 3px solid #474747;
        font-family: "Hoefler Text", "Baskerville Old Face", Garamond, "Times New Roman", serif;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.55; }
      #main #content .quote .quote-footer {
        display: flex;
        justify-content: space-between;
        margin: 2rem 1rem 0 1rem;
        padding: 1rem;
        font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
        font-size: 0.9rem; }
        #main #content .quote .quote-footer cite {
          color: #fff;
          font-style: normal;
          margin-left: 1rem; }
        #main #content .quote .quote-footer span {
          color: #fff;
          margin-right: 1rem; }
      #main #content .quote:before {
        content: '';
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        width: 125px;
        height: 125px;
        background-color: #f8f8ff;
        border-radius: 500px;
        border: 3px solid #474747;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: grayscale(90%); }
      #main #content .quote.kvothe:before {
        background-image: url(src/images/kvothe.png); }
      #main #content .quote.abenthy:before {
        background-image: url(src/images/abenthy.png); }
      #main #content .quote.arliden:before {
        background-image: url(src/images/arliden.jpg); }
      #main #content .quote.auri:before {
        background-image: url(src/images/auri.jpg); }
      #main #content .quote.bast:before {
        background-image: url(src/images/bast.jpg); }
      #main #content .quote.deoch:before {
        background-image: url(src/images/deoch.jpg); }
      #main #content .quote.master:before {
        background-image: url(src/images/elodin.jpg); }
      #main #content .quote.patrick:before {
        background-image: url(src/images/pat.jpg); }
      #main #content .quote.skarpi:before {
        background-image: url(src/images/skarpi.png); }
      #main #content .quote.vashet:before {
        background-image: url(src/images/vashet.jpg); }
      #main #content .quote::after {
        content: '';
        margin: 0; }
    #main #content.all {
      padding-top: 100px; }
  #main #rando {
    position: fixed;
    bottom: 2rem; }

.random-quotes #main {
  height: 100%; }
  .random-quotes #main #content {
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 7.5rem 0; }
